




























































































































































































































































import Vue from "vue";
import { mapActions, mapState } from "pinia";
import { mapGetters } from "vuex";
import FormDialog from "@/components/FormDialog.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import { Discussion, useConversationStore } from "@/store/conversationStore";
import dayjs from "dayjs";
import { IInviteUser, InviteUser, UserClient } from "@/api/DoceoApi";

export default Vue.extend({
  name: "Discussions",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  components: { DoceoIcon, FormDialog },
  data: () => ({
    isDiscussionShowing: false, // Keep track of whether the discussion section is showing. On mobile, only the discussions OR chat section is showing at a time
    isCreateDialogShowing: false, // Keep track of whether the discussion create dialog is showing
    isCreateSubmitting: false, // Disables create button while waiting for the API
    isInviteDialogShowing: false, // Keep track of whether the discussion invite dialog is showing
    isInviteSubmitting: false, // Disables invite button while waiting for the API
    inviteEmailText: "", // The email address to invite
    createTitleText: "", // The title of the new discussion
    selectedDiscussion: null as Discussion | null, // The discussion that is currently selected by the user,
    newMessage: "", // The new message being sent by the user
    DOCEO_ICONS,
    inviteFirstNameText: "",
    inviteLastNameText: "",
    showNewUserInputs: false,
  }),
  created() {
    this.goToQueryChatroom();
  },
  methods: {
    ...mapActions(useConversationStore, [
      "setCurrentChatroom",
      "createChatroom",
      "sendMessage",
      "leaveChatroom",
      "inviteUserToChatroom",
      "readLastMessage",
      "getHistoricalMessages",
    ]),
    /**
     * If there is at least 1 discussion, open the specified chatroom from the query string.
     * If there is no query string, open the first discussion on the list if the screen size is not mobile-sized.
     */
    goToQueryChatroom() {
      if (this.discussions.length > 1) {
        if (this.$route.query.chatroomId) {
          const chatroom = this.discussions.find((x) => x.id === this.$route.query.chatroomId);
          if (chatroom) {
            this.selectedDiscussion = chatroom;
            this.selectedDiscussionChanged();
          }
        } else if (!this.isSmallScreen) {
          this.selectedDiscussion = this.discussions[0];
          this.selectedDiscussionChanged();
        }
      }
    },
    /**
     * Opens the create dialog. Called when the create button is pressed.
     */
    createClicked() {
      this.isCreateDialogShowing = true;
    },
    /**
     * Check if the dates are the same on the messages. This is used in the chat window to display the date in between messages of different dates
     */
    isSameDate(firstIndex: number, secondIndex: number): boolean {
      if (secondIndex <= firstIndex) return false;
      if (firstIndex < 0) return false;
      if (secondIndex > this.messages.length) return false;
      let x = this.convertUtcToLocal(this.messages[firstIndex].createdUtc).format("DD/MM/YYYY");
      let y = this.convertUtcToLocal(this.messages[secondIndex].createdUtc).format("DD/MM/YYYY");
      if (x === y) return true;
      return false;
    },
    /**
     * Check if the author name is the same on the messages.
     * This is used in the chat window to only display the author name above the first message if they send a series of messages
     */
    isSameAuthor(firstIndex: number, secondIndex): boolean {
      if (secondIndex <= firstIndex) return false;
      if (firstIndex < 0) return false;
      if (secondIndex > this.messages.length) return false;
      if (this.messages[secondIndex].fromUserFullName == this.messages[firstIndex].fromUserFullName) return true;
      return false;
    },
    /**
     * Triggered when a discussion is selected. This will load the conversations for the selected discussion and scroll to the bottom of the chat window.
     */
    async selectedDiscussionChanged() {
      this.newMessage = "";
      if (this.selectedDiscussion) {
        await this.setCurrentChatroom(this.selectedDiscussion.id);
        this.scrollToBottom();
      } else {
        await this.setCurrentChatroom("");
      }
    },
    /**
     * Scroll to the bottom of the chat window by finding the element with the scrollToMe reference. This should always be the last message in the chat
     */
    scrollToBottom() {
      Vue.nextTick(() => {
        this.isDiscussionShowing = true;

        var container: any = this.$refs["scrollToMe"];
        if (container && container.length > 0) {
          let x = container[0];
          x.scrollIntoView({ behavior: "auto" }); // Note: Vuetify also has a scroll function
        }
      });
    },
    /**
     * Indicate that the discussion is closed. This will hide the discussion window on a small screen. On a larger screen (md & up) the icon won't appear
     */
    async closeDiscussion() {
      this.isDiscussionShowing = false;
      this.selectedDiscussion = null;
      await this.setCurrentChatroom("");
    },
    /**
     * Triggered when the send message button is pressed. This will send the message to the API and scroll to the bottom of the chat window.
     */
    async sendMessagePressed() {
      if (this.newMessage && this.selectedDiscussion) {
        await this.sendMessage(this.newMessage);
        this.scrollToBottom();
        this.newMessage = "";
      }
    },
    /**
     * Triggered when the leave discussion button is pressed. This will tell the API to remove the user from the chatroom, and close the current chat window.
     */
    async leaveDiscussionPressed() {
      if (this.selectedDiscussion) {
        await this.leaveChatroom(this.selectedDiscussion);
        this.closeDiscussion();
      }
    },
    /**
     * Triggered when the invite to discussion button is pressed. This will tell the API to invite a user to the chatroom, and close the invite dialog.
     */
    async inviteToDiscussion(form) {
      let userClient = new UserClient();

      if (this.inviteEmailText && this.selectedDiscussion) {
        // If we are showing the new user inputs
        if (this.showNewUserInputs) {
          let userInvite: IInviteUser = {
            firstName: this.inviteFirstNameText,
            lastName: this.inviteLastNameText,
            emailAddress: this.inviteEmailText,
            chatroomId: this.selectedDiscussion.id,
            folioId: "",
          };

          await userClient.inviteUser(new InviteUser(userInvite));
        } else {
          let doesUserExist = await userClient.doesEmailExist(this.inviteEmailText);

          if (doesUserExist) {
            this.isInviteSubmitting = true;
            await this.inviteUserToChatroom(this.inviteEmailText).finally(() => {
              this.isInviteSubmitting = false;
            });
            this.isInviteDialogShowing = false;
            form.reset();
          } else {
            this.showNewUserInputs = true;
          }
        }
      }
    },
    /**
     * Triggered when the create discussion button is pressed. This will tell the API to create a new chatroom, and close the create dialog.
     */
    async createDiscussion(form) {
      if (this.createTitleText) {
        this.isCreateSubmitting = true;
        await this.createChatroom(this.createTitleText).finally(() => {
          this.isCreateSubmitting = false;
        });
        this.isCreateDialogShowing = false;
        form.reset();
      }
    },
    /**
     * The chat window scroll handler. Loads additional historic messages if the chat element is scrolled to the top.
     */
    async onScroll(e) {
      if (this.messages.length === 0) return;
      const target = e.target;
      if (target.scrollTop === 0) {
        const prevScrollHeight = target.scrollHeight;
        await this.getHistoricalMessages();
        const newScrollHeight = target.scrollHeight;
        target.scrollTo(0, newScrollHeight - prevScrollHeight);
      }
    },
    /**
     * Checks to see if the chat element is scrolled to the bottom. This is used to only scroll the chat window to the bottom on a new message if the user hasn't scrolled up.
     */
    checkScrolledToBottom() {
      const chatElement: any = this.$refs["messageList"];
      if (!chatElement) {
        return false;
      }
      return Math.ceil(chatElement.scrollTop + chatElement.offsetHeight) >= chatElement.scrollHeight;
    },
    /**
     * Converts a time from UTC to the user's local time. Returns a DayJS object.
     */
    convertUtcToLocal(utcTime: string) {
      return dayjs.utc(utcTime).local();
    },
  },
  computed: {
    ...mapGetters(["name"]),
    ...mapState(useConversationStore, ["messages", "discussions"]),
    /**
     * Determine the height of the discussion panel.
     * TODO: Find a better way to calculate this value
     */
    discussionHeight() {
      return "calc(100vh - 173px)";
    },
    /**
     * Determine the height of the chat panel.
     * On mobile the height is slightly different as the "Send" button for the chat goes onto a new line
     * TODO: Find a better way to calculate this value
     */
    chatHeight() {
      if (this.isSmallScreen) {
        return "calc(100vh - 327px)";
      }
      return "calc(100vh - 275px)";
    },
    /**
     * Determine whether the user is currently viewing on a "small" screen.
     * Currently we define small screens to be of size "xs" or "sm"
     */
    isSmallScreen(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": // < 600px
          return true;
        case "sm": // 600px > < 960px
          return true;
        case "md": // 960px > < 1264px
          return false;
        case "lg": // 1264px > < 1904px
          return false;
        case "xl": //	> 1904px
          return false;
        default:
          return false;
      }
    },
  },
  watch: {
    messages: function(val, prev) {
      if (prev.length === 0 || this.checkScrolledToBottom()) {
        this.scrollToBottom();
        if (this.selectedDiscussion) {
          this.readLastMessage(this.selectedDiscussion);
        }
      }
    },
    $route(to, from) {
      this.goToQueryChatroom();
    },
  },
});
